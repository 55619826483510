import {
  useCachedGet,
  useSecureSessionPost,
} from '~~/src/composables/dataFetching/genericFetchers';
import type { Result as GetResult } from '~~/src/server/api/[site]/user/register.get';
import type { Result } from '~~/src/server/api/[site]/user/register.post';
import type { RegistrationFormData } from '../types';
import type { ErrorIdent } from '~/@types/errorIdents';
import type { UserData } from '@/server/gateway/connections/shopware/actions/registerUser';
import { CustomerGroupId } from '~/@types/customerGroupIds';
import useCustomerGroupMaps from '~/composables/user/useCustomerGroupMaps';
import { useSessionStore } from '~/stores/useSessionStore';
import { handleLoadingError } from '~/utils/handleLoadingError';
import { componentVisibilitySettings as componentVisibilitySettings_IK } from '@/injectionSymbols';
import useValidateAddressExternal from '~/components/formTools/address/validation/composables/useValidateAddressExternal';
import { getNode } from '@formkit/core';
import useRecaptcha from '@/components/formTools/recaptcha/useRecaptcha';

export function useRegistrationForm(isInDialogRef: Ref<boolean>) {
  const site = useSiteIdent();
  const useValidateAddress = useValidateAddressExternal();
  const registerData = ref<GetResult>(null);
  const errors = ref<ErrorIdent[]>([]);

  const isLoading = ref(false);
  const initialized = ref(false);
  const injected = inject(componentVisibilitySettings_IK);
  const showSuccessScreen = ref(false);
  const customerTypeOptions = ref([]);
  const groups = ref([]);
  const selectedType = ref();
  const session = useSessionStore();
  const { captcha } = useRecaptcha();
  const usesCaptcha = computed(() => !!captcha.value);

  const hasVerificationFiles = computed(() => {
    return useCustomerGroupMaps().verificationNeeded[site].includes(
      selectedType.value,
    );
  });

  const canPossiblyBuyDrugs = computed(() => {
    return useCustomerGroupMaps().drugPurchasePossible[site].includes(
      selectedType.value,
    );
  });

  const isInDialog = ref(false);
  watch(
    () => isInDialogRef.value,
    (v) => {
      isInDialog.value = v;
    },
  );

  if (import.meta.client) {
    setTimeout(() => load(), 0);
  }

  async function load() {
    const result = await useCachedGet<GetResult>(`/api/${site}/user/register`);
    const lang = useNuxtApp().$languageCode;

    if (result.data?.value?.groups && result.data?.value?.countries) {
      registerData.value = result.data.value;
      groups.value = result.data.value.groups;
      customerTypeOptions.value = result.data.value.groups
        .sort(function (a: any, b: any) {
          return a.sort.localeCompare(b.sort, lang, { numeric: true });
        })
        .map((group) => ({
          value: group.id,
          label: group.name,
        }));
      selectedType.value = customerTypeOptions.value[0].value;
      initialized.value = true;
    }
  }

  async function checkValidity() {
    const form = document.getElementById('registration-form');
    if (!form) return;

    // Sleep for quarter second till form is updated with validation information
    await new Promise((r) => setTimeout(r, 250));
    const invalidElements = form.querySelectorAll('[data-invalid=true]');

    if (!invalidElements.length) return;

    invalidElements[0].scrollIntoView({
      behavior: 'smooth',
    });
  }

  async function onSubmit(data: RegistrationFormData) {
    //needed because when the form is subitted while the input is focused, formkit throws an error when it tries to blur the already unmounted input
    (document.activeElement as HTMLElement)?.blur();
    isLoading.value = true;

    try {
      await useValidateAddress.validateAddress({
        countryId: registerData.value.countries.find(
          (country) => country.code === data.country,
        )?.code,
        city: data.zipCity.city,
        zipcode: data.zipCity.zip,
        street: data.streetNumber.street,
        number: data.streetNumber.houseNumber,
      });
    } catch (e) {
      handleLoadingError(e);
      // We just keep going and ignore the validation if it fails  (for any reason)
    }

    if (useValidateAddress.needsManualCheck.value) {
      isLoading.value = false;
      return;
    }

    await register(data);
    isLoading.value = false;
  }

  async function register(formData: RegistrationFormData) {
    try {
      const postData: UserData = {
        groupId: selectedType.value,
        title: formData.title,
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        billingEmail: formData.billingEmail,
        password: formData.password.password,
        confirmPassword: formData.password.confirmPassword,
        billingAddress: {
          name1: formData.name1,
          name2: formData.name2 ?? '',
          street: formData.streetNumber.street,
          number: formData.streetNumber.houseNumber,
          zipcode: formData.zipCity.zip,
          city: formData.zipCity.city,
          countryId: registerData.value.countries.find(
            (country) => country.code === formData.country,
          )?.id,
          phone: formData.phone.fullPhoneNumber,
          taxId: formData.hasTaxId ? formData.taxId : undefined,
        },
      };

      const fileInputs = document.querySelectorAll('[name^="files"]');
      const body = new FormData();
      body.append('userData', JSON.stringify(postData));
      body.append(
        'captcha',
        JSON.stringify(
          await (captcha.value ? captcha.value?.execute('submit') : null),
        ),
      );
      fileInputs.forEach((input) => {
        if ((input as HTMLInputElement)?.files?.length) {
          body.append(
            (input as HTMLInputElement).name,
            (input as HTMLInputElement)?.files?.[0],
          );
        }
      });

      const data = await useSecureSessionPost<Result>(
        `/api/${site}/user/register`,
        body,
      );

      if (data?.sessionId) {
        session.login(data.sessionId);
        showSuccessScreen.value = true;
      }

      isLoading.value = false;
    } catch (e: any) {
      errors.value = e?.data?.data?.errors ?? [];
      if (isInDialog.value) {
        document.querySelector('.js-dialog-scroll-container')?.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else
        document.querySelector('.js-error-container')?.scrollIntoView({
          behavior: 'smooth',
        });
      isLoading.value = false;
      handleLoadingError(e);
    }
  }

  function handleFillAddress(data: any) {
    useValidateAddress.resetManualCheck();
    getNode('registrationStreet').input(data.street);
    getNode('registrationHouseNumber').input(data.number);
    getNode('registrationCity').input(data.city);
    getNode('registrationZip').input(data.zipcode);
  }

  const showWarning = computed(
    () =>
      injected?.showRegistrationHint &&
      (selectedType.value === CustomerGroupId.OTHER ||
        selectedType.value === CustomerGroupId.STUDENT ||
        selectedType.value === CustomerGroupId.COMPANY),
  );
  return {
    onSubmit,
    customerTypeOptions,
    isLoading,
    initialized,
    errors,
    checkValidity,
    showSuccessScreen,
    registerData,
    selectedType,
    hasVerificationFiles,
    showWarning,
    canPossiblyBuyDrugs,
    needsManualCheck: useValidateAddress.needsManualCheck,
    resetManualCheck: useValidateAddress.resetManualCheck,
    validationResult: useValidateAddress.validationResult,
    handleFillAddress,
    usesCaptcha,
  };
}
