<template>
  <TextInput
    :id="id"
    v-model="value"
    :name="name"
    :label="label"
    :placeholder="placeholder"
    required-message="formFields.email.error"
    :validation-visibility="validationVisibility"
    matches-message="formFields.email.error"
    :input-class="disabled && hasWhiteBg ? 'bg-[#eeeeee] cursor-no-drop' : ''"
    :validation="[
      [
        'matches',
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      ],
    ]"
    :maxlength="80"
    @blur="$emit('blur')"
  />
</template>
<script setup lang="ts">
import { TextInput } from '@/complib';

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  id: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  modelValue: {
    type: String,
    default: '',
  },
  validationVisibility: {
    type: String as PropType<'blur' | 'submit' | 'live' | 'dirty'>,
    default: 'blur',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  hasWhiteBg: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'blur'): void;
}>();

const value = ref<string>(props.modelValue);

watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue;
  },
);

watch(value, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>
